var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-4" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c(
                  "b-dropdown",
                  {
                    staticClass: "float-right ml-2",
                    attrs: {
                      variant: "white",
                      right: "",
                      "toggle-class": "text-muted"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "button-content",
                        fn: function() {
                          return [
                            _c("i", {
                              staticClass:
                                "mdi mdi-dots-horizontal font-size-18"
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _c("b-dropdown-item", [_vm._v("Action")]),
                    _c("b-dropdown-item", [_vm._v("Another action")]),
                    _c("b-dropdown-item", [_vm._v("Something else here")])
                  ],
                  1
                ),
                _c("div", [
                  _c("div", { staticClass: "mb-4 mr-3" }, [
                    _c("i", {
                      staticClass: "mdi mdi-account-circle text-primary h1"
                    })
                  ]),
                  _c("div", [
                    _c("h5", [_vm._v("Henry Wells")]),
                    _c("p", { staticClass: "text-muted mb-1" }, [
                      _vm._v("henrywells@abc.com")
                    ]),
                    _c("p", { staticClass: "text-muted mb-0" }, [
                      _vm._v("Id no: #SK0234")
                    ])
                  ])
                ])
              ],
              1
            ),
            _c("div", { staticClass: "card-body border-top" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", [
                    _c("p", { staticClass: "font-weight-medium mb-2" }, [
                      _vm._v("Balance :")
                    ]),
                    _c("h4", [_vm._v("$ 6134.39")])
                  ])
                ]),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", { staticClass: "mt-4 mt-sm-0" }, [
                    _c("p", { staticClass: "font-weight-medium mb-2" }, [
                      _vm._v("Coin :")
                    ]),
                    _c(
                      "div",
                      { staticClass: "d-inline-flex align-items-center mt-1" },
                      [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass: "m-1",
                            attrs: {
                              href: "javascript: void(0);",
                              "data-placement": "top",
                              title: "Bitcoin"
                            }
                          },
                          [
                            _c("div", { staticClass: "avatar-xs" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "avatar-title rounded-circle bg-soft-warning text-warning font-size-18"
                                },
                                [_c("i", { staticClass: "mdi mdi-bitcoin" })]
                              )
                            ])
                          ]
                        ),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass: "m-1",
                            attrs: {
                              href: "javascript: void(0);",
                              "data-placement": "top",
                              title: "Ethereum"
                            }
                          },
                          [
                            _c("div", { staticClass: "avatar-xs" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
                                },
                                [_c("i", { staticClass: "mdi mdi-ethereum" })]
                              )
                            ])
                          ]
                        ),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass: "m-1",
                            attrs: {
                              href: "javascript: void(0);",
                              "data-placement": "top",
                              title: "Litecoin"
                            }
                          },
                          [
                            _c("div", { staticClass: "avatar-xs" }, [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "avatar-title rounded-circle bg-soft-info text-info font-size-18"
                                },
                                [_c("i", { staticClass: "mdi mdi-litecoin" })]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _c(
              "div",
              { staticClass: "card-footer bg-transparent border-top" },
              [
                _c("div", { staticClass: "text-center" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-outline-light mr-2 w-md",
                      attrs: { href: "javascript: void(0);" }
                    },
                    [_vm._v("Deposit")]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary mr-2 w-md",
                      attrs: { href: "javascript: void(0);" }
                    },
                    [_vm._v("Buy / Sell")]
                  )
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "col-xl-8" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-9 col-sm-8" }, [
                  _c("div", { staticClass: "p-4" }, [
                    _c("h5", { staticClass: "text-primary" }, [
                      _vm._v("Welcome Back !")
                    ]),
                    _c("p", [_vm._v("Skote Crypto Dashboard")]),
                    _c("div", { staticClass: "text-muted" }, [
                      _c("p", { staticClass: "mb-1" }, [
                        _c("i", {
                          staticClass:
                            "mdi mdi-circle-medium align-middle text-primary mr-1"
                        }),
                        _vm._v(" If several languages coalesce ")
                      ]),
                      _c("p", { staticClass: "mb-1" }, [
                        _c("i", {
                          staticClass:
                            "mdi mdi-circle-medium align-middle text-primary mr-1"
                        }),
                        _vm._v(" Sed ut perspiciatis unde ")
                      ]),
                      _c("p", { staticClass: "mb-0" }, [
                        _c("i", {
                          staticClass:
                            "mdi mdi-circle-medium align-middle text-primary mr-1"
                        }),
                        _vm._v(" It would be necessary ")
                      ])
                    ])
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "col-lg-3 col-sm-4 align-self-center" },
                  [
                    _c("div", [
                      _c("img", {
                        staticClass: "img-fluid d-block",
                        attrs: {
                          src: require("@/assets/images/crypto/features-img/img-1.png"),
                          alt: ""
                        }
                      })
                    ])
                  ]
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-4" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("p", { staticClass: "text-muted mb-4" }, [
                    _c("i", {
                      staticClass:
                        "mdi mdi-bitcoin h2 text-warning align-middle mb-0 mr-3"
                    }),
                    _vm._v(" Bitcoin ")
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _c("div", [
                        _c("h5", [_vm._v("$ 9134.39")]),
                        _c(
                          "p",
                          { staticClass: "text-muted text-truncate mb-0" },
                          [
                            _vm._v(" + 0.0012 ( 0.2 % ) "),
                            _c("i", {
                              staticClass: "mdi mdi-arrow-up ml-1 text-success"
                            })
                          ]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "div",
                        [
                          _c("apexchart", {
                            staticClass: "apex-charts",
                            attrs: {
                              height: "40",
                              type: "area",
                              dir: "ltr",
                              series: _vm.bitconinChart.series,
                              options: _vm.bitconinChart.chartOptions
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "col-sm-4" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("p", { staticClass: "text-muted mb-4" }, [
                    _c("i", {
                      staticClass:
                        "mdi mdi-ethereum h2 text-primary align-middle mb-0 mr-3"
                    }),
                    _vm._v(" Ethereum ")
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _c("div", [
                        _c("h5", [_vm._v("$ 245.44")]),
                        _c(
                          "p",
                          { staticClass: "text-muted text-truncate mb-0" },
                          [
                            _vm._v(" - 4.102 ( 0.1 % ) "),
                            _c("i", {
                              staticClass: "mdi mdi-arrow-down ml-1 text-danger"
                            })
                          ]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "div",
                        [
                          _c("apexchart", {
                            staticClass: "apex-charts",
                            attrs: {
                              height: "40",
                              type: "area",
                              dir: "ltr",
                              series: _vm.ethereumChart.series,
                              options: _vm.ethereumChart.chartOptions
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "col-sm-4" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("p", { staticClass: "text-muted mb-4" }, [
                    _c("i", {
                      staticClass:
                        "mdi mdi-litecoin h2 text-info align-middle mb-0 mr-3"
                    }),
                    _vm._v(" litecoin ")
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _c("div", [
                        _c("h5", [_vm._v("$ 63.61")]),
                        _c(
                          "p",
                          { staticClass: "text-muted text-truncate mb-0" },
                          [
                            _vm._v(" + 1.792 ( 0.1 % ) "),
                            _c("i", {
                              staticClass: "mdi mdi-arrow-up ml-1 text-success"
                            })
                          ]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "div",
                        [
                          _c("apexchart", {
                            staticClass: "apex-charts",
                            attrs: {
                              height: "40",
                              type: "area",
                              dir: "ltr",
                              series: _vm.litecoinChart.series,
                              options: _vm.litecoinChart.chartOptions
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-8" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "float-right" }, [
                _c(
                  "select",
                  { staticClass: "custom-select custom-select-sm ml-2" },
                  [
                    _c("option", { attrs: { value: "1", selected: "" } }, [
                      _vm._v("March")
                    ]),
                    _c("option", { attrs: { value: "2" } }, [
                      _vm._v("February")
                    ]),
                    _c("option", { attrs: { value: "3" } }, [
                      _vm._v("January")
                    ]),
                    _c("option", { attrs: { value: "4" } }, [
                      _vm._v("December")
                    ])
                  ]
                )
              ]),
              _c("h4", { staticClass: "card-title mb-3" }, [
                _vm._v("Wallet Balance")
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-4" }, [
                  _c("div", { staticClass: "mt-4" }, [
                    _c("p", [_vm._v("Available Balance")]),
                    _c("h4", [_vm._v("$ 6134.39")]),
                    _c("p", { staticClass: "text-muted mb-4" }, [
                      _vm._v(" + 0.0012.23 ( 0.2 % ) "),
                      _c("i", {
                        staticClass: "mdi mdi-arrow-up ml-1 text-success"
                      })
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-6" }, [
                        _c("div", [
                          _c("p", { staticClass: "mb-2" }, [_vm._v("Income")]),
                          _c("h5", [_vm._v("$ 2632.46")])
                        ])
                      ]),
                      _c("div", { staticClass: "col-6" }, [
                        _c("div", [
                          _c("p", { staticClass: "mb-2" }, [_vm._v("Expense")]),
                          _c("h5", [_vm._v("$ 924.38")])
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "mt-4" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary btn-sm",
                          attrs: { href: "#" }
                        },
                        [
                          _vm._v(" View more "),
                          _c("i", { staticClass: "mdi mdi-arrow-right ml-1" })
                        ]
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "col-lg-4 col-sm-6" }, [
                  _c(
                    "div",
                    [
                      _c("apexchart", {
                        staticClass: "apex-charts",
                        attrs: {
                          dir: "ltr",
                          type: "radialBar",
                          height: "300",
                          series: _vm.walletRadialChart.series,
                          options: _vm.walletRadialChart.chartOptions
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "col-lg-4 col-sm-6 align-self-center" },
                  [
                    _c("div", [
                      _c("p", { staticClass: "mb-2" }, [
                        _c("i", {
                          staticClass:
                            "mdi mdi-circle align-middle font-size-10 mr-2 text-primary"
                        }),
                        _vm._v(" Ethereum ")
                      ]),
                      _c("h5", [
                        _vm._v(" 4.5701 ETH = "),
                        _c("span", { staticClass: "text-muted font-size-14" }, [
                          _vm._v("$ 1123.64")
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "mt-4 pt-2" }, [
                      _c("p", { staticClass: "mb-2" }, [
                        _c("i", {
                          staticClass:
                            "mdi mdi-circle align-middle font-size-10 mr-2 text-warning"
                        }),
                        _vm._v(" Bitcoin ")
                      ]),
                      _c("h5", [
                        _vm._v(" 0.4412 BTC = "),
                        _c("span", { staticClass: "text-muted font-size-14" }, [
                          _vm._v("$ 4025.32")
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "mt-4 pt-2" }, [
                      _c("p", { staticClass: "mb-2" }, [
                        _c("i", {
                          staticClass:
                            "mdi mdi-circle align-middle font-size-10 mr-2 text-info"
                        }),
                        _vm._v(" Litecoin ")
                      ]),
                      _c("h5", [
                        _vm._v(" 35.3811 LTC = "),
                        _c("span", { staticClass: "text-muted font-size-14" }, [
                          _vm._v("$ 2263.09")
                        ])
                      ])
                    ])
                  ]
                )
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "col-xl-4" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h4", { staticClass: "card-title mb-3" }, [
                _vm._v("Overview")
              ]),
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "apex-charts",
                    attrs: { id: "overview-chart", dir: "ltr" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "toolbar button-items text-center" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-light btn-sm",
                            attrs: { type: "button", id: "one_month" }
                          },
                          [_vm._v("1M")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-light btn-sm",
                            attrs: { type: "button", id: "six_months" }
                          },
                          [_vm._v("6M")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-light btn-sm active",
                            attrs: { type: "button", id: "one_year" }
                          },
                          [_vm._v("1Y")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-light btn-sm",
                            attrs: { type: "button", id: "all" }
                          },
                          [_vm._v("ALL")]
                        )
                      ]
                    ),
                    _c("apexchart", {
                      staticClass: "apex-charts",
                      attrs: {
                        type: "area",
                        height: "275",
                        dir: "ltr",
                        series: _vm.overviewChart.series,
                        options: _vm.overviewChart.chartOptions
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-4" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("h4", { staticClass: "card-title mb-4" }, [
                  _vm._v("Transactions")
                ]),
                _c(
                  "b-tabs",
                  {
                    attrs: {
                      pills: "",
                      "nav-class": "bg-light rounded",
                      "content-class": "mt-4"
                    }
                  },
                  [
                    _c(
                      "b-tab",
                      { attrs: { title: "All", active: "" } },
                      [
                        _c(
                          "b-card-text",
                          [
                            _c(
                              "simplebar",
                              { staticStyle: { "max-height": "340px" } },
                              [
                                _c(
                                  "table",
                                  {
                                    staticClass:
                                      "table table-centered table-nowrap"
                                  },
                                  [
                                    _c(
                                      "tbody",
                                      _vm._l(_vm.transactionsData, function(
                                        data
                                      ) {
                                        return _c("tr", { key: data.icon }, [
                                          _c(
                                            "td",
                                            { staticStyle: { width: "50px" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  class:
                                                    "font-size-22 text-" +
                                                    data.color
                                                },
                                                [
                                                  _c("i", {
                                                    class: {
                                                      "bx bx-down-arrow-circle":
                                                        "" + data.color ===
                                                        "primary",
                                                      "bx bx-up-arrow-circle":
                                                        "" + data.color ===
                                                        "danger"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          ),
                                          _c("td", [
                                            _c("div", [
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "font-size-14 mb-1"
                                                },
                                                [_vm._v(_vm._s(data.name))]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "text-muted mb-0"
                                                },
                                                [_vm._v(_vm._s(data.date))]
                                              )
                                            ])
                                          ]),
                                          _c("td", [
                                            _c(
                                              "div",
                                              { staticClass: "text-right" },
                                              [
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "font-size-14 mb-0"
                                                  },
                                                  [_vm._v(_vm._s(data.text))]
                                                )
                                              ]
                                            )
                                          ]),
                                          _c("td", [
                                            _c(
                                              "div",
                                              { staticClass: "text-right" },
                                              [
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "font-size-14 text-muted mb-0"
                                                  },
                                                  [_vm._v(_vm._s(data.price))]
                                                )
                                              ]
                                            )
                                          ])
                                        ])
                                      }),
                                      0
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "Buy" } },
                      [
                        _c(
                          "b-card-text",
                          [
                            _c(
                              "simplebar",
                              { staticStyle: { "max-height": "340px" } },
                              [
                                _c(
                                  "table",
                                  {
                                    staticClass:
                                      "table table-centered table-nowrap"
                                  },
                                  [
                                    _c(
                                      "tbody",
                                      _vm._l(_vm.transactionsData, function(
                                        data
                                      ) {
                                        return _c("tr", { key: data.id }, [
                                          _c(
                                            "td",
                                            { staticStyle: { width: "50px" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  class:
                                                    "font-size-22 text-" +
                                                    data.color
                                                },
                                                [
                                                  _c("i", {
                                                    class: {
                                                      "bx bx-down-arrow-circle":
                                                        "" + data.color ===
                                                        "primary",
                                                      "bx bx-up-arrow-circle":
                                                        "" + data.color ===
                                                        "danger"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          ),
                                          _c("td", [
                                            _c("div", [
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "font-size-14 mb-1"
                                                },
                                                [_vm._v(_vm._s(data.name))]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "text-muted mb-0"
                                                },
                                                [_vm._v(_vm._s(data.date))]
                                              )
                                            ])
                                          ]),
                                          _c("td", [
                                            _c(
                                              "div",
                                              { staticClass: "text-right" },
                                              [
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "font-size-14 mb-0"
                                                  },
                                                  [_vm._v(_vm._s(data.text))]
                                                )
                                              ]
                                            )
                                          ]),
                                          _c("td", [
                                            _c(
                                              "div",
                                              { staticClass: "text-right" },
                                              [
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "font-size-14 text-muted mb-0"
                                                  },
                                                  [_vm._v(_vm._s(data.price))]
                                                )
                                              ]
                                            )
                                          ])
                                        ])
                                      }),
                                      0
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "Sell" } },
                      [
                        _c(
                          "b-card-text",
                          [
                            _c(
                              "simplebar",
                              { staticStyle: { "max-height": "340px" } },
                              [
                                _c(
                                  "table",
                                  {
                                    staticClass:
                                      "table table-centered table-nowrap"
                                  },
                                  [
                                    _c(
                                      "tbody",
                                      _vm._l(_vm.transactionsData, function(
                                        data
                                      ) {
                                        return _c("tr", { key: data.id }, [
                                          _c(
                                            "td",
                                            { staticStyle: { width: "50px" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  class:
                                                    "font-size-22 text-" +
                                                    data.color
                                                },
                                                [
                                                  _c("i", {
                                                    class: {
                                                      "bx bx-down-arrow-circle":
                                                        "" + data.color ===
                                                        "primary",
                                                      "bx bx-up-arrow-circle":
                                                        "" + data.color ===
                                                        "danger"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          ),
                                          _c("td", [
                                            _c("div", [
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "font-size-14 mb-1"
                                                },
                                                [_vm._v(_vm._s(data.name))]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "text-muted mb-0"
                                                },
                                                [_vm._v(_vm._s(data.date))]
                                              )
                                            ])
                                          ]),
                                          _c("td", [
                                            _c(
                                              "div",
                                              { staticClass: "text-right" },
                                              [
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "font-size-14 mb-0"
                                                  },
                                                  [_vm._v(_vm._s(data.text))]
                                                )
                                              ]
                                            )
                                          ]),
                                          _c("td", [
                                            _c(
                                              "div",
                                              { staticClass: "text-right" },
                                              [
                                                _c(
                                                  "h5",
                                                  {
                                                    staticClass:
                                                      "font-size-14 text-muted mb-0"
                                                  },
                                                  [_vm._v(_vm._s(data.price))]
                                                )
                                              ]
                                            )
                                          ])
                                        ])
                                      }),
                                      0
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "col-xl-4" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h4", { staticClass: "card-title mb-4" }, [
                _vm._v("Notifications")
              ]),
              _c(
                "ul",
                { staticClass: "list-group" },
                [
                  _c("simplebar", { staticStyle: { "max-height": "400px" } }, [
                    _c("li", { staticClass: "list-group-item" }, [
                      _c("div", { staticClass: "media" }, [
                        _c("div", { staticClass: "avatar-xs mr-3" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "avatar-title rounded-circle bg-light"
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/companies/img-1.png"),
                                  alt: "",
                                  height: "18"
                                }
                              })
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "media-body" }, [
                          _c("h5", { staticClass: "font-size-14" }, [
                            _vm._v("Donec vitae sapien ut")
                          ]),
                          _c("p", { staticClass: "text-muted" }, [
                            _vm._v(
                              " If several languages coalesce, the grammar of the resulting language "
                            )
                          ]),
                          _c("div", { staticClass: "float-right" }, [
                            _c("p", { staticClass: "text-muted mb-0" }, [
                              _c("i", { staticClass: "mdi mdi-account mr-1" }),
                              _vm._v(" Joseph ")
                            ])
                          ]),
                          _c("p", { staticClass: "text-muted mb-0" }, [
                            _vm._v("12 Mar, 2020")
                          ])
                        ])
                      ])
                    ]),
                    _c("li", { staticClass: "list-group-item" }, [
                      _c("div", { staticClass: "media" }, [
                        _c("div", { staticClass: "avatar-xs mr-3" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "avatar-title rounded-circle bg-light"
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/companies/img-2.png"),
                                  alt: "",
                                  height: "18"
                                }
                              })
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "media-body" }, [
                          _c("h5", { staticClass: "font-size-14" }, [
                            _vm._v("Cras ultricies mi eu turpis")
                          ]),
                          _c("p", { staticClass: "text-muted" }, [
                            _vm._v(
                              " To an English person, it will seem like simplified English, as a skeptical cambridge "
                            )
                          ]),
                          _c("div", { staticClass: "float-right" }, [
                            _c("p", { staticClass: "text-muted mb-0" }, [
                              _c("i", { staticClass: "mdi mdi-account mr-1" }),
                              _vm._v(" Jerry ")
                            ])
                          ]),
                          _c("p", { staticClass: "text-muted mb-0" }, [
                            _vm._v("13 Mar, 2020")
                          ])
                        ])
                      ])
                    ]),
                    _c("li", { staticClass: "list-group-item" }, [
                      _c("div", { staticClass: "media" }, [
                        _c("div", { staticClass: "avatar-xs mr-3" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "avatar-title rounded-circle bg-light"
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/companies/img-3.png"),
                                  alt: "",
                                  height: "18"
                                }
                              })
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "media-body" }, [
                          _c("h5", { staticClass: "font-size-14" }, [
                            _vm._v("Duis arcu tortor suscipit")
                          ]),
                          _c("p", { staticClass: "text-muted" }, [
                            _vm._v(
                              " It va esser tam simplic quam occidental in fact, it va esser occidental. "
                            )
                          ]),
                          _c("div", { staticClass: "float-right" }, [
                            _c("p", { staticClass: "text-muted mb-0" }, [
                              _c("i", { staticClass: "mdi mdi-account mr-1" }),
                              _vm._v(" Calvin ")
                            ])
                          ]),
                          _c("p", { staticClass: "text-muted mb-0" }, [
                            _vm._v("14 Mar, 2020")
                          ])
                        ])
                      ])
                    ]),
                    _c("li", { staticClass: "list-group-item" }, [
                      _c("div", { staticClass: "media" }, [
                        _c("div", { staticClass: "avatar-xs mr-3" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "avatar-title rounded-circle bg-light"
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/companies/img-1.png"),
                                  alt: "",
                                  height: "18"
                                }
                              })
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "media-body" }, [
                          _c("h5", { staticClass: "font-size-14" }, [
                            _vm._v("Donec vitae sapien ut")
                          ]),
                          _c("p", { staticClass: "text-muted" }, [
                            _vm._v(
                              " If several languages coalesce, the grammar of the resulting language "
                            )
                          ]),
                          _c("div", { staticClass: "float-right" }, [
                            _c("p", { staticClass: "text-muted mb-0" }, [
                              _c("i", { staticClass: "mdi mdi-account mr-1" }),
                              _vm._v(" Joseph ")
                            ])
                          ]),
                          _c("p", { staticClass: "text-muted mb-0" }, [
                            _vm._v("12 Mar, 2020")
                          ])
                        ])
                      ])
                    ])
                  ])
                ],
                1
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "col-xl-4" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("h4", { staticClass: "card-title mb-4" }, [
                  _vm._v("Buy / Sell")
                ]),
                _c(
                  "b-tabs",
                  {
                    attrs: {
                      pills: "",
                      "nav-class": "bg-light rounded",
                      "content-class": "mt-4"
                    }
                  },
                  [
                    _c(
                      "b-tab",
                      { attrs: { title: "Buy", active: "" } },
                      [
                        _c("b-card-text", [
                          _c("div", { staticClass: "float-right ml-2" }, [
                            _c("h5", { staticClass: "font-size-14" }, [
                              _c("i", {
                                staticClass:
                                  "bx bx-wallet text-primary font-size-16 align-middle mr-1"
                              }),
                              _vm._v(" $4235.23 ")
                            ])
                          ]),
                          _c("h5", { staticClass: "font-size-14 mb-4" }, [
                            _vm._v("Buy Coin")
                          ]),
                          _c("div", [
                            _c("div", { staticClass: "form-group mb-3" }, [
                              _c("label", [_vm._v("Payment method :")]),
                              _c("select", { staticClass: "custom-select" }, [
                                _c("option", [_vm._v("Credit / Debit Card")]),
                                _c("option", [_vm._v("Paypal")])
                              ])
                            ]),
                            _c("div", [
                              _c("label", [_vm._v("Add Amount :")]),
                              _c("div", { staticClass: "input-group mb-3" }, [
                                _c(
                                  "div",
                                  { staticClass: "input-group-prepend" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "input-group-text" },
                                      [_vm._v("Amount")]
                                    )
                                  ]
                                ),
                                _c(
                                  "select",
                                  {
                                    staticClass: "custom-select",
                                    staticStyle: { "max-width": "90px" }
                                  },
                                  [
                                    _c(
                                      "option",
                                      { attrs: { value: "1", selected: "" } },
                                      [_vm._v("BTC")]
                                    ),
                                    _c("option", { attrs: { value: "2" } }, [
                                      _vm._v("ETH")
                                    ]),
                                    _c("option", { attrs: { value: "3" } }, [
                                      _vm._v("LTC")
                                    ])
                                  ]
                                ),
                                _c("input", {
                                  staticClass: "form-control",
                                  attrs: { type: "text" }
                                })
                              ]),
                              _c("div", { staticClass: "input-group mb-3" }, [
                                _c(
                                  "div",
                                  { staticClass: "input-group-prepend" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "input-group-text" },
                                      [_vm._v("Price")]
                                    )
                                  ]
                                ),
                                _c("input", {
                                  staticClass: "form-control",
                                  attrs: { type: "text" }
                                }),
                                _c(
                                  "div",
                                  { staticClass: "input-group-append" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "input-group-text" },
                                      [_vm._v("$")]
                                    )
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "input-group mb-3" }, [
                                _c(
                                  "div",
                                  { staticClass: "input-group-prepend" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "input-group-text" },
                                      [_vm._v("Total")]
                                    )
                                  ]
                                ),
                                _c("input", {
                                  staticClass: "form-control",
                                  attrs: { type: "text" }
                                })
                              ])
                            ]),
                            _c("div", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-success w-md",
                                  attrs: { type: "button" }
                                },
                                [_vm._v("Buy Coin")]
                              )
                            ])
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "Sell" } },
                      [
                        _c("b-card-text", [
                          _c("div", { staticClass: "float-right ml-2" }, [
                            _c("h5", { staticClass: "font-size-14" }, [
                              _c("i", {
                                staticClass:
                                  "bx bx-wallet text-primary font-size-16 align-middle mr-1"
                              }),
                              _vm._v(" $4235.23 ")
                            ])
                          ]),
                          _c("h5", { staticClass: "font-size-14 mb-4" }, [
                            _vm._v("Sell Coin")
                          ]),
                          _c("div", [
                            _c("div", { staticClass: "form-group mb-3" }, [
                              _c("label", [_vm._v("Email :")]),
                              _c("input", {
                                staticClass: "form-control",
                                attrs: { type: "email" }
                              })
                            ]),
                            _c("div", [
                              _c("label", [_vm._v("Add Amount :")]),
                              _c("div", { staticClass: "input-group mb-3" }, [
                                _c(
                                  "div",
                                  { staticClass: "input-group-prepend" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "input-group-text" },
                                      [_vm._v("Amount")]
                                    )
                                  ]
                                ),
                                _c(
                                  "select",
                                  {
                                    staticClass: "custom-select",
                                    staticStyle: { "max-width": "90px" }
                                  },
                                  [
                                    _c(
                                      "option",
                                      { attrs: { value: "1", selected: "" } },
                                      [_vm._v("BTC")]
                                    ),
                                    _c("option", { attrs: { value: "2" } }, [
                                      _vm._v("ETH")
                                    ]),
                                    _c("option", { attrs: { value: "3" } }, [
                                      _vm._v("LTC")
                                    ])
                                  ]
                                ),
                                _c("input", {
                                  staticClass: "form-control",
                                  attrs: { type: "text" }
                                })
                              ]),
                              _c("div", { staticClass: "input-group mb-3" }, [
                                _c(
                                  "div",
                                  { staticClass: "input-group-prepend" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "input-group-text" },
                                      [_vm._v("Price")]
                                    )
                                  ]
                                ),
                                _c("input", {
                                  staticClass: "form-control",
                                  attrs: { type: "text" }
                                }),
                                _c(
                                  "div",
                                  { staticClass: "input-group-append" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "input-group-text" },
                                      [_vm._v("$")]
                                    )
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "input-group mb-3" }, [
                                _c(
                                  "div",
                                  { staticClass: "input-group-prepend" },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "input-group-text" },
                                      [_vm._v("Total")]
                                    )
                                  ]
                                ),
                                _c("input", {
                                  staticClass: "form-control",
                                  attrs: { type: "text" }
                                })
                              ])
                            ]),
                            _c("div", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger w-md",
                                  attrs: { type: "button" }
                                },
                                [_vm._v("Sell Coin")]
                              )
                            ])
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }